<template>
  <ExpensesRow :parentSpoilerName="parentSpoilerName">
    <slot></slot>
  </ExpensesRow>
</template>

<script>
import ExpensesRow from "./ExpensesRow";

export default {
  name: "AddNewTaskRow",
  components: {
    ExpensesRow,
  },
  props: {
    parentSpoilerName: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
