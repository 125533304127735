var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"cellRef",staticClass:"expenses__body-cell",class:{
    'expenses__body-cell--non-editable': _vm.isGeneralCell && !_vm.isEditable,
    'expenses__body-cell--editable': _vm.isEditable,
    'expenses__body-cell--add-cell': _vm.isAddCell,
    'expenses__body-cell--today':
      (_vm.numberOfColumn !== 0) & (_vm.numberOfColumn !== 1) &&
      (_vm.getIsItDayToday ||
        (_vm.isSelectedIntervalIncludeCurrentDay &&
          _vm.getDayNumber - 1 === _vm.getMyDate.date())),
    'expenses__body-cell--monday': _vm.getIsItMonday,
    'expenses__body-cell--workday': !_vm.isItWeekendDay,
    'expenses__body-cell--weekend-day': _vm.isItWeekendDay,
    'expenses__body-cell--last-cell': _vm.isItLastCell,
    'expenses__body-cell--sticky':
      _vm.numberOfColumn === 0 || _vm.numberOfColumn === 1,
    'expenses__body-cell--head': _vm.isHeadCell,
    'expenses__body-cell--focused': _vm.isFocused,
  },style:({
    left:
      _vm.numberOfColumn === 0
        ? `${_vm.zeroColumnLeftIndent}px`
        : _vm.numberOfColumn === 1
        ? `${_vm.leftIndent}px`
        : '0',
  }),on:{"click":function($event){return _vm.changeFocusCellNumber(_vm.numberOfColumn)}}},[(_vm.isAddCell && _vm.addCellModeOn)?[_c('form',{staticClass:"expenses__body-cell-data expenses__body-cell-data--form",on:{"submit":function($event){$event.preventDefault();return _vm.addNewCell.apply(null, arguments)}}},[_c('input',{directives:[{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model.trim",value:(_vm.addCellDataInput),expression:"addCellDataInput",modifiers:{"trim":true}}],staticClass:"expenses__body-cell-data expenses__body-cell-data-add--input",attrs:{"placeholder":"введите название","type":"text"},domProps:{"value":(_vm.addCellDataInput)},on:{"input":function($event){if($event.target.composing)return;_vm.addCellDataInput=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"expenses__body-cell-data expenses__body-cell-data--buttons"},[_c('input',{staticClass:"expenses__body-cell-data expenses__body-cell-data--buttons-item",attrs:{"type":"button","value":"отмена"},on:{"click":() => {
              this.addCellModeOn = false;
              this.addCellDataInput = '';
            }}}),_c('input',{staticClass:"expenses__body-cell-data expenses__body-cell-data--buttons-item",attrs:{"type":"button","value":"ok"},on:{"click":_vm.addNewCell}})])])]:[(_vm.isEditable && !_vm.isGeneralCell)?[(_vm.isTimeTrackAllowed)?_c('Input',{ref:"inputRef",attrs:{"startValue":_vm.data,"is-only-numbers":true,"classNames":{
          'expenses__body-cell-data': true,
          'expenses__body-cell-data--input': true,
          'expenses__body-cell-data--weekend-day': _vm.isItWeekendDay,
          'expenses__body-cell-data--too-much-tracked':
            _vm.isTrackedTooMuch && _vm.isTimeTrackAllowed,
        },"save-data":_vm.saveDataInput,"disabled":this.getReadonly,"timeFormat":_vm.expensesTimeFormat},on:{"number-out-range":_vm.showRangeError}}):_c('div',{staticClass:"expenses__body-cell-data--not-allowed"},[_c('Hatch',{staticClass:"expenses__body-cell-data--not-allowed-hatch"}),_vm._v(" "+_vm._s(_vm.data)+" ")],1)]:[(
          _vm.isGeneralCell &&
          _vm.isEditable &&
          _vm.isOpen === null &&
          !this.changeTaskModeOn &&
          _vm.isTimeTrackAllowed &&
          _vm.isSelectedIntervalIncludeCurrentDay &&
          !this.getReadonly
        )?_c('div',{class:{
          'expenses__body-cell-data': true,
          'expenses__body-cell-timer-icon': true,
          'expenses__body-cell-timer-icon--visible':
            _vm.isTimerActive && _vm.timer.taskId === _vm.taskId,
        }},[(!_vm.isTimerActive)?[_c('Button',{attrs:{"theme":"hoverIcon"},on:{"click":_vm.timerStartHandler}},[_c('StartIcon')],1)]:_vm._e(),(_vm.isTimerActive && _vm.timer.taskId === _vm.taskId)?[_c('Button',{attrs:{"theme":"icon"},on:{"click":_vm.timerStopHandler}},[_c('StopIcon')],1)]:_vm._e()],2):_vm._e(),(
          !_vm.isSelectedIntervalIncludeCurrentDay &&
          _vm.isGeneralCell &&
          _vm.dataOfRestoredTask.isIconVisible
        )?_c('div',{staticClass:"expenses__body-cell-data expenses__body-cell-restore-icon"},[_c('Button',{attrs:{"theme":"hoverIcon"},on:{"click":_vm.onTaskRestoreClick}},[_c('Tooltip',{attrs:{"tooltipData":'Перенести задачу в текущий месяц',"withIcon":true,"customIcon":_vm.ClockIcon}})],1)],1):_vm._e(),(!this.changeTaskModeOn)?_c('div',{staticClass:"expenses__body-cell-data expenses__body-cell-data--name",class:{
          'expenses__body-cell-data--disabled':
            !this.isCurrentIntervalHaveAccess,
          'expenses__body-cell-data--bold-weight':
            _vm.isFontWeightBold && !_vm.getReadonly,
        },on:{"click":_vm.cellClick}},[(_vm.isOpen !== null)?_c('span',{staticClass:"expenses__body-cell-spoiler-icon"},[_c('SpoilerIcon',{attrs:{"styleObject":{
              transform: _vm.isOpen ? 'rotate(0)' : 'rotate(-90deg)',
            }}})],1):_vm._e(),(_vm.isGeneralCell && !!_vm.data)?_c('Tooltip',{attrs:{"tooltipData":_vm.data,"tooltip-background":_vm.isEditable ? '#fff' : '#ccc',"classNames":{ 'expenses__body-cell-data--text': true },"editHandler":_vm.isEditable && !this.getReadonly ? _vm.openChangeTaskMode : null,"size-update-time":_vm.sizeUpdateTime}}):_c('p',{staticClass:"expenses__body-cell-data--text"},[_vm._v(_vm._s(_vm.data))]),(_vm.projectDescription)?_c('div',{staticClass:"expenses__body-cell-data--description"},[_c('Tooltip',{attrs:{"tooltipData":_vm.projectDescription,"tooltip-background":'#fff',"withIcon":true}})],1):_vm._e()],1):_c('form',{staticClass:"expenses__body-cell-data expenses__body-cell-data--form",on:{"submit":function($event){$event.preventDefault();return _vm.changeTask.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.changeTaskDataInput),expression:"changeTaskDataInput",modifiers:{"trim":true}}],ref:"taskDataInputRef",staticClass:"expenses__body-cell-data expenses__body-cell-data-add--input",attrs:{"autofocus":"","placeholder":"введите название","type":"text"},domProps:{"value":(_vm.changeTaskDataInput)},on:{"input":function($event){if($event.target.composing)return;_vm.changeTaskDataInput=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"expenses__body-cell-data expenses__body-cell-data--buttons"},[_c('input',{staticClass:"expenses__body-cell-data expenses__body-cell-data--buttons-item",attrs:{"type":"button","value":"отмена"},on:{"click":() => {
                this.changeTaskModeOn = false;
                this.changeTaskDataInput = this.defaultTaskDataInput;
              }}}),_c('input',{staticClass:"expenses__body-cell-data expenses__body-cell-data--buttons-item",attrs:{"type":"button","value":"ok"},on:{"click":function($event){$event.preventDefault();return _vm.changeTask.apply(null, arguments)}}})])]),(_vm.hoursRatio)?_c('div',{staticClass:"expenses__body-cell-data expenses__body-cell-data--hours"},[_vm._v(" "+_vm._s(_vm.hoursRatio)+" ")]):_vm._e(),(
          _vm.isGeneralCell &&
          _vm.isEditable &&
          _vm.isOpen === null &&
          !this.changeTaskModeOn &&
          _vm.isTimeTrackAllowed
        )?_c('div',{staticClass:"expenses__body-cell-data expenses__icons"},[(!this.getReadonly)?[_c('div',{staticClass:"expenses__icons-eye-wrapper"},[_c('Button',{attrs:{"theme":"hoverIcon"},on:{"click":function($event){return _vm.checkRowVisibility(_vm.taskId)}}},[_c('ShowIcon')],1)],1),_c('div',{staticClass:"expenses__icons-trash-wrapper"},[_c('Button',{attrs:{"theme":"hoverIcon","disabled":!_vm.isCurrentIntervalHaveAccess || _vm.isCurrentTaskActive},on:{"click":function($event){!_vm.isCurrentTaskActive && _vm.clickDeleteTask()}}},[_c('Trash')],1)],1)]:_vm._e()],2):_vm._e()]],(_vm.numberOfColumn === 1)?_c('div',{staticClass:"expenses__body-cell--sticky-border"},[_c('div',{staticClass:"expenses__body-cell-resizer",attrs:{"draggable":"true"},on:{"dragstart":_vm.onResizeStart,"drag":_vm.resizeColumn,"dragend":_vm.resizeEnd}})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }