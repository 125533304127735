<template>
  <div
    v-show="isChanges"
    :style="coords"
    class="control__resizer-indicator"
  ></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ExpensesColumnResizer",
  data() {
    return {
      tableElem: {},
      firstColumnLeft: 0,
    };
  },
  mounted() {
    this.tableElem = document.getElementById("expenses-table");
    this.firstColumnLeft =
      document
        .querySelector(".expenses__body-cell--sticky")
        .getBoundingClientRect().left ?? 0;
  },
  computed: {
    ...mapState({
      resizeX: (state) => state.expensesStore.resizeX,
      resizeStatus: (state) => state.expensesStore.resizeStatus,
    }),
    isChanges() {
      return this.resizeStatus === "start";
    },
    coords() {
      const HEADER_HEIGHT = 50;
      const SUM_COLUMN_WIDTH = 50;
      const tableCoords =
        this.tableElem instanceof Element
          ? this.tableElem.getBoundingClientRect()
          : {
              top: 0,
              bottom: 0,
              left: 0,
            };
      return {
        top: tableCoords.top + HEADER_HEIGHT + "px",
        height: tableCoords.height - HEADER_HEIGHT + "px",
        left: this.resizeX + this.firstColumnLeft + SUM_COLUMN_WIDTH + "px",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.control__resizer-indicator {
  width: 2px;
  position: fixed;
  z-index: 9;
  background-color: $erp-primary-bg-color;
}
</style>
