<template>
  <Toggler
    title="Показывать отработанные задачи"
    :value="isHiddenRowsVisible"
    :isChecked="isHiddenRowsVisible"
    :onToggleHandler="onToggleHandler"
    :togglerWidth="90"
  >
    <template #firstLabel>
      <template>
        <EyeIcon />
      </template>
    </template>
    <template #secondLabel>
      <HideIcon />
    </template>
  </Toggler>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import EyeIcon from "@/assets/img/eye-icon.svg";
import HideIcon from "@/assets/img/hide-eye-icon.svg";
import Toggler from "@/components/ui/Toggler.vue";

export default {
  name: "ExpensesTasksToggler",
  components: {
    EyeIcon,
    HideIcon,
    Toggler,
  },
  methods: {
    onToggleHandler(event) {
      this.setHiddenRowsVisibility(!!event.target.checked);
    },
    ...mapMutations({
      setHiddenRowsVisibility: "expensesStore/setHiddenRowsVisibility",
    }),
  },
  computed: {
    ...mapState({
      isHiddenRowsVisible: (state) => state.expensesStore.isHiddenRowsVisible,
    }),
  },
};
</script>
