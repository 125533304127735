<template>
  <modal :data="modalData" class="modal--vacation" ref="modal">
    <p>Вы хотите удалить задачу?</p>
  </modal>
</template>

<script>
import modal from "./ModalProto";

export default {
  name: "DeleteModal",
  components: { modal },
  props: {},
  data() {
    return {
      modalData: {
        title: "Подтверждение удаления задачи",
        hasOverlay: true,
        actions: [
          {
            id: "accept",
            title: `Удалить`,
            classes: "modal-message__accept",
            handler: () => {
              this.$emit("confirmDeleteTask");
              this.$refs.modal.close();
            },
          },
          {
            id: "cancel",
            title: `Отменить`,
            classes: "modal-message__cancel",
            handler: () => this.$refs.modal.close(),
          },
        ],
      },
    };
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
      this.$refs.modal.setPosition("center");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/modal.scss";
</style>
