<template>
  <ExpensesRow
    :isNonEditable="true"
    :rowNumber="`${rowData?.name}-${getHoursRatio}-${getSpoilerLevel}`"
    :parentSpoilerName="getParentSpoilerName"
  >
    <ExpensesCell
      :style="{ 'padding-left': getSpoilerLevel * 8 + 'px' }"
      :data="rowData?.name"
      :projectDescription="rowData?.description"
      :hours-ratio="getHoursRatio"
      :is-open="getIsCellOpened"
      :onSpoilerClick="onSpoilerClick"
      :numberOfColumn="0"
      :is-general-cell="true"
      :isFontWeightBold="spoilerType === 'project'"
    />
    <ExpensesCell
      :is-general-cell="true"
      :numberOfColumn="1"
      :isItMonday="true"
    />
    <ExpensesCell
      v-for="n in getSelectedInterval.amount"
      :key="n"
      :numberOfColumn="n + 1"
      :isItLastCell="n === getSelectedInterval.amount"
      :data="customCellsData(n)"
      style="justify-content: center"
    />
  </ExpensesRow>
</template>

<script>
import ExpensesRow from "./ExpensesRow";
import ExpensesCell from "./ExpensesCell";
import { mapGetters, mapMutations } from "vuex";
import { getLocalStorage } from "@/assets/utils";

export default {
  name: "ProjectRow",
  components: {
    ExpensesRow,
    ExpensesCell,
  },
  data() {
    return {
      isClosed: false,
    };
  },
  props: {
    rowData: {
      default() {
        return null;
      },
    },
    spoilerType: {
      type: String,
      default() {
        return null;
      },
    },
    spoilerName: {
      type: String,
      default() {
        return this.rowData?.name;
      },
    },
    customCellsData: {
      default() {
        return () => "";
      },
    },
  },
  methods: {
    ...mapMutations({
      setClosedRows: "expensesStore/setClosedRows",
    }),
    onSpoilerClick() {
      let closedSpoilerIds = [];
      if (this.getReadonly) {
        closedSpoilerIds = this.getClosedRows;
      } else {
        closedSpoilerIds =
          JSON.parse(getLocalStorage("CLOSED_SPOILER_IDS")) || [];
      }

      const currentClosedSpoilerIndex = closedSpoilerIds?.findIndex(
        (openedSpoilerId) => openedSpoilerId === this.spoilerName
      );

      if (currentClosedSpoilerIndex === -1) {
        closedSpoilerIds.push(this.spoilerName);
      } else {
        closedSpoilerIds.splice(currentClosedSpoilerIndex, 1);
      }

      if (!this.getReadonly) {
        window.localStorage.setItem(
          "CLOSED_SPOILER_IDS",
          JSON.stringify(closedSpoilerIds)
        );
      }
      this.setClosedRows(closedSpoilerIds);
    },
  },
  computed: {
    ...mapGetters({
      getSelectedInterval: "expensesStore/getSelectedInterval",
      getMyDate: "userStore/getMyDate",
      getClosedRows: "expensesStore/getClosedRows",
      getReadonly: "expensesStore/getReadonly",
    }),
    getSpoilerLevel() {
      return this.spoilerName.split("--").length;
    },
    getParentSpoilerName() {
      if (!this.spoilerName) {
        return "";
      }

      const spoilerNameMass = this.spoilerName.split("--");
      if (spoilerNameMass?.length === 1) {
        return "";
      }

      const parentSpoilerName = spoilerNameMass
        .slice(0, spoilerNameMass.length - 1)
        .join("--");

      return parentSpoilerName;
    },
    getHoursRatio() {
      if (!this.rowData) {
        return "";
      }

      const planHours = this.rowData.sum_plan_hours
        ? this.rowData.sum_plan_hours
        : this.rowData.plan_hours;

      return `${this.rowData.sum_actual_hours?.toFixed(1) || 0} / ${
        planHours || 0
      }`;
    },
    getIsCellOpened() {
      return !this.getClosedRows?.find(
        (closedRow) => closedRow === this.spoilerName
      );
    },
  },
};
</script>

<style scoped></style>
