<template>
  <div
    v-if="!getReadonly && getFocusedCellPosition.numberOfColumn >= 0"
    class="expenses__control"
  >
    <div
      v-if="focusedCellPosition.left"
      class="control__vertical-highlight control__vertical-highlight-left"
      :style="{ left: getLeftStyleValue }"
    />
    <div
      v-if="focusedCellPosition.right"
      class="control__vertical-highlight control__vertical-highlight-right"
      :style="{ left: getRightStyleValue }"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "ExpensesControl",
  data() {
    return {
      focusedCellPosition: {
        left: 0,
        right: 0,
      },
      focusedCellRef: null,
    };
  },
  mounted() {
    setTimeout(() => {
      const options = { capture: true };
      const callback = () => {
        this.calculateStyleValues();
      };
      window.addEventListener("resize", callback, options);
      this.$once("hook:beforeDestroy", () =>
        window.removeEventListener("resize", callback, options)
      );
      this.setCellPosition(this.getFocusedCellPosition);
    }, 100);
  },
  methods: {
    calculateStyleValues() {
      const cell = this.focusedCellRef;
      if (!cell || !document.body.contains(cell)) {
        return;
      }
      const LINE_WIDTH = 1;
      const left = cell.offsetLeft + LINE_WIDTH;
      const right = left + cell.offsetWidth;
      this.focusedCellPosition = { left, right };
    },
    setCellPosition(cellPosition) {
      if (cellPosition.isVisionOn && cellPosition.numberOfColumn >= 0) {
        this.setCellRef();
        this.calculateStyleValues();
      }
    },
    setCellRef() {
      if (this.getFocusedCellPosition.cellRef) {
        this.focusedCellRef = this.getFocusedCellPosition.cellRef;
      } else if (this.getFocusedCellPosition.numberOfColumn) {
        const refSelector = `div[data-col-number='${this.getFocusedCellPosition.numberOfColumn}']`;
        this.focusedCellRef = document.querySelector(refSelector) || null;
      }
    },
  },
  computed: {
    ...mapState({
      sizeUpdateTime: (state) => state.expensesStore.sizeUpdateTime,
    }),
    ...mapGetters({
      getReadonly: "expensesStore/getReadonly",
      getFocusedCellPosition: "expensesStore/getFocusedCellPosition",
    }),
    getLeftStyleValue() {
      return this.focusedCellPosition.left
        ? this.focusedCellPosition.left + 1 + "px"
        : 0;
    },
    getRightStyleValue() {
      return this.focusedCellPosition.right
        ? this.focusedCellPosition.right + "px"
        : 0;
    },
  },
  watch: {
    getFocusedCellPosition: {
      handler(newCellPosition) {
        this.setCellPosition(newCellPosition);
      },
      deep: true,
    },
    sizeUpdateTime() {
      this.calculateStyleValues();
    },
  },
};
</script>

<style lang="scss" scoped>
.control__vertical-highlight {
  height: calc(100% - 1px);
  width: 1px;
  position: absolute;
  left: 0;
  top: 1px;
  background-color: #6fa8dc;
  z-index: 8;
}
</style>
