<template :key="expensesTimeFormat">
  <Toggler
    title="Время заполняется в"
    :value="expensesTimeFormat"
    :isChecked="expensesTimeFormat === 'minutes'"
    :onToggleHandler="onFormatChangeHandler"
    :togglerWidth="170"
  >
    <template #firstLabel>минутах</template>
    <template #secondLabel>часах</template>
  </Toggler>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Toggler from "@/components/ui/Toggler.vue";

export default {
  name: "ExpensesTimeFormat",
  components: { Toggler },
  methods: {
    getInterval(data) {
      this.$emit("getInterval", data);
    },
    onFormatChangeHandler(event) {
      if (event.target.checked) {
        this.setExpensesTimeFormat("minutes");
        window.localStorage.setItem("EXPENSES_TIME_FORMAT", "minutes");
      } else {
        this.setExpensesTimeFormat("hours");
        window.localStorage.setItem("EXPENSES_TIME_FORMAT", "hours");
      }
    },
    ...mapMutations({
      setExpensesTimeFormat: "expensesStore/setExpensesTimeFormat",
    }),
  },
  computed: {
    ...mapState({
      expensesTimeFormat: (state) => state.expensesStore.expensesTimeFormat,
    }),
  },
};
</script>
