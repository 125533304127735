<template>
  <div class="container">
    <div class="expenses__title">
      <h2 class="expenses__title--text">Мои трудозатраты</h2>
    </div>
    <template v-if="!error">
      <ExpensesIntervalSelect
        :lastPlanningDate="lastPlanningDate"
        :firstPlanningDate="firstPlanningDate"
        @getInterval="getInterval"
        style="position: sticky; left: 190px"
      />

      <ExpensesTimeFormat style="position: sticky; left: 190px" />

      <ExpensesTasksToggler style="position: sticky; left: 190px" />

      <MyExpensesTable
        :is-loading="isLoading"
        :intervalData="intervalData"
        :expensesList="expensesList"
      />
    </template>
    <div v-else>
      {{ error }}
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
import MyExpensesTable from "../../components/expenses/ExpensesTable";
import ExpensesIntervalSelect from "@/components/expenses/ExpensesIntervalSelect";
import ExpensesTimeFormat from "@/components/expenses/ExpensesTimeFormat";
import ExpensesTasksToggler from "@/components/expenses/ExpensesTasksToggler";
import { getLocalStorage } from "../../assets/utils";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

export default {
  components: {
    ExpensesIntervalSelect,
    ExpensesTimeFormat,
    ExpensesTasksToggler,
    MyExpensesTable,
  },
  data() {
    return {
      isLoading: true,
      intervalData: {
        amount: 0,
        since: "",
        until: "",
      },
    };
  },
  async created() {
    await this.loadPlanningsList();
  },
  mounted() {
    const timeFormat = getLocalStorage("EXPENSES_TIME_FORMAT");
    if (timeFormat) {
      this.setExpensesTimeFormat(timeFormat);
    } else {
      this.setExpensesTimeFormat("hours");
      window.localStorage.setItem("EXPENSES_TIME_FORMAT", "hours");
    }

    const hiddenTasks =
      JSON.parse(getLocalStorage("EXPENSES_HIDDEN_TASKS")) || [];
    this.setExpensesHiddenTasks(hiddenTasks);
  },
  methods: {
    async getInterval(data) {
      if (!data.length) {
        return;
      }
      try {
        this.isLoading = true;

        if (!this.getCalendarYears.length) {
          await this.loadCalendarYears();
        }

        let currentPartOfData = 0;
        if (data[1]) {
          //todo проверять, на какой части разрывной недели мы находимся и оттуда брать месяцы
          currentPartOfData = 1;
        }

        const currentYearNumber = parseInt(
          data[currentPartOfData].since.split("-")[0]
        );
        const currentYearId = this.getCalendarYears.find(
          (year) => year.number === currentYearNumber
        ).id;
        await this.loadCalendarMonths(currentYearId);

        const currentMonthNumber = parseInt(
          data[currentPartOfData].since.split("-")[1]
        );
        const month_id = this.getCalendarMonths.find(
          (month) => month.name === currentMonthNumber
        ).id;
        await this.loadCalendarDays({ month_id });

        if (data.length) {
          if (data.length === 2) {
            const [firstPart, secondPart] = data;
            this.intervalData = {
              amount: 7,
              since: firstPart.since,
              until: secondPart.until,
            };
          } else {
            this.intervalData = data[0];
          }
          for (const [index, interval] of data.entries()) {
            if (index > 0) {
              interval.isPart = true;
            }
            await this.loadExpensesList(interval);

            if (
              !dayjs(this.getMyDate).isBetween(
                interval.since,
                interval.until
              ) &&
              !this.getCurrentMonthExpensesList.length
            ) {
              const currentInterval = this.getCurrentInterval();
              await this.loadExpensesList({
                ...currentInterval,
                isOnlyCurrent: true,
              });
              this.setCurrentInterval(currentInterval);
            }
            this.setSelectedInterval(interval);
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    getCurrentInterval() {
      const format = "YYYY-MM-DD";
      return {
        amount: dayjs(this.getMyDate).daysInMonth(),
        since: dayjs(this.getMyDate).startOf("month").format(format),
        until: dayjs(this.getMyDate).endOf("month").format(format),
      };
    },
    ...mapActions({
      loadExpensesList: "expensesStore/loadExpensesList",
      loadCalendarDays: "productionCalendarStore/loadCalendarDays",
      loadCalendarYears: "productionCalendarStore/loadCalendarYears",
      loadCalendarMonths: "productionCalendarStore/loadCalendarMonths",
      loadPlanningsList: "planningsStore/loadPlanningsList",
    }),
    ...mapMutations({
      setSelectedInterval: "expensesStore/setSelectedInterval",
      setCurrentInterval: "expensesStore/setCurrentInterval",
      setExpensesTimeFormat: "expensesStore/setExpensesTimeFormat",
      setExpensesHiddenTasks: "expensesStore/setExpensesHiddenTasks",
    }),
  },
  computed: {
    ...mapState({
      planningsList: (state) => state.planningsStore.planningsList,
      expensesList: (state) => state.expensesStore.expensesList,
      error: (state) => state.expensesStore.error,
      currentDaysCalendar: (state) =>
        state.productionCalendarStore.currentDaysCalendar,
      expensesHiddenTasks: (state) => state.expensesStore.expensesHiddenTasks,
    }),
    ...mapGetters({
      getExpensesList: "expensesStore/getExpensesList",
      getCalendarYears: "productionCalendarStore/getCalendarYears",
      getCalendarMonths: "productionCalendarStore/getCalendarMonths",
      getMyDate: "userStore/getMyDate",
      getCurrentMonthExpensesList: "expensesStore/getCurrentMonthExpensesList",
    }),
    lastPlanningDate() {
      let date;
      if (this.planningsList.length) {
        const [lastDate] = this.planningsList;
        date = dayjs(`${lastDate.year.number}-${lastDate.name}`).format(
          "YYYY-MM"
        );
      }
      return dayjs(date).format("YYYY-MM");
    },
    firstPlanningDate() {
      let date;
      if (this.planningsList.length) {
        const firstDate = this.planningsList[this.planningsList.length - 1];
        date = dayjs(`${firstDate.year.number}-${firstDate.name}`).format(
          "YYYY-MM"
        );
      }
      return dayjs(date).format("YYYY-MM");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.expenses__title {
  .expenses__title--text {
    display: inline-block;
    position: sticky;
    left: 190px;
  }
}
</style>
